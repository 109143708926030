<template>
  <section id="dashboard-ecommerce">
    <b-card>
      <h1>Statistiques sur les frontières terrestres</h1>
      <div class="mt-2">
        <h3>Filtres de recherche</h3>
        <StatisticsFilters
          v-model="filtersForm"
          :active-filters="activeFilters"
          :is-loading="isLoadingTableData"
          @submit="getStatistics"
        />
      </div>
    </b-card>

    <b-row class="match-height">
      <!-- <b-col lg="6">
        <StatsEtablissementParService />
      </b-col> -->
      <!-- Par pays -->
      <b-col lg="12">
        <VoyageursParPaysEntree
          v-if="statsData.statsEntreesFrontieresTerrestres"
          :table-datas="statsData.statsEntreesFrontieresTerrestres.parPays"
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <VoyageursParPaysSortie
          v-if="statsData.statsSortiesFrontieresTerrestres"
          :table-datas="statsData.statsSortiesFrontieresTerrestres.parPays"
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>

      <b-col lg="12">
        <StatsMotifsVoyage
          :table-datas="statsData.parMotifsVoyageEntreeEtSortie"
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <StatsCategorieSocioPro
          :table-datas="statsData.parCategorieSocioProEntreeEtSortie"
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>

      <!-- Duree totale de sejour -->
      <b-col lg="12">
        <DureeTotalSejourParCategorieSocioProEntree
          v-if="statsData.statsEntreesFrontieresTerrestres"
          :table-datas="
            statsData.statsEntreesFrontieresTerrestres.parCategorieSocioPro
          "
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <DureeTotalSejourParCategorieSocioProSortie
          v-if="statsData.statsSortiesFrontieresTerrestres"
          :table-datas="
            statsData.statsSortiesFrontieresTerrestres.parCategorieSocioPro
          "
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <DureeTotalSejourParMotifDeVoyageEntree
          v-if="statsData.statsEntreesFrontieresTerrestres"
          :table-datas="
            statsData.statsEntreesFrontieresTerrestres.parMotifsVoyage
          "
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <DureeTotalSejourParMotifDeVoyageSortie
          v-if="statsData.statsSortiesFrontieresTerrestres"
          :table-datas="
            statsData.statsSortiesFrontieresTerrestres.parMotifsVoyage
          "
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <DureeTotalSejourParPaysEntree
          v-if="statsData.statsEntreesFrontieresTerrestres"
          :table-datas="statsData.statsEntreesFrontieresTerrestres.parPays"
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <DureeTotalSejourParPaysSortie
          v-if="statsData.statsSortiesFrontieresTerrestres"
          :table-datas="statsData.statsSortiesFrontieresTerrestres.parPays"
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>

      <!-- Duree moyenne de sejour -->
      <b-col lg="12">
        <DureeMoyenneSejourParCategorieSocioProEntree
          v-if="statsData.statsEntreesFrontieresTerrestres"
          :table-datas="
            statsData.statsEntreesFrontieresTerrestres.parCategorieSocioPro
          "
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <DureeMoyenneSejourParCategorieSocioProSortie
          v-if="statsData.statsSortiesFrontieresTerrestres"
          :table-datas="
            statsData.statsSortiesFrontieresTerrestres.parCategorieSocioPro
          "
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <DureeMoyenneSejourParMotifDeVoyageEntree
          v-if="statsData.statsEntreesFrontieresTerrestres"
          :table-datas="
            statsData.statsEntreesFrontieresTerrestres.parMotifsVoyage
          "
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
      <b-col lg="12">
        <DureeMoyenneSejourParMotifDeVoyageSortie
          v-if="statsData.statsSortiesFrontieresTerrestres"
          :table-datas="
            statsData.statsSortiesFrontieresTerrestres.parMotifsVoyage
          "
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import { mapActions } from 'vuex'
import utilsService from '@/services/utils/utils.service'
import StatisticsFilters from '@/components/StatisticsFilters.vue'
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import statisticsStoreModule from '@/store/statistics'
import formulaireStoreModule from '@/store/formulaire'
import { buildFiltersForRequest } from '@/helpers/http-utils'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import {
  listCategoriesSocioPro,
  listMotifsVoyage,
} from '@/services/utils/constants'
import VoyageursParPaysSortie from './VoyageursParPaysSortie.vue'

import DureeTotalSejourParMotifDeVoyageEntree from './entrees/DureeTotalSejourParMotifDeVoyageEntree.vue'
import DureeTotalSejourParMotifDeVoyageSortie from './DureeTotalSejourParMotifDeVoyageSortie.vue'
import DureeTotalSejourParCategorieSocioProEntree from './entrees/DureeTotalSejourParCategorieSocioProEntree.vue'
import DureeTotalSejourParCategorieSocioProSortie from './DureeTotalSejourParCategorieSocioProSortie.vue'
import DureeTotalSejourParPaysEntree from './entrees/DureeTotalSejourParPaysEntree.vue'
import DureeTotalSejourParPaysSortie from './DureeTotalSejourParPaysSortie.vue'

import DureeMoyenneSejourParMotifDeVoyageEntree from './entrees/DureeMoyenneSejourParMotifDeVoyageEntree.vue'
import DureeMoyenneSejourParMotifDeVoyageSortie from './DureeMoyenneSejourParMotifDeVoyageSortie.vue'
import DureeMoyenneSejourParCategorieSocioProEntree from './entrees/DureeMoyenneSejourParCategorieSocioProEntree.vue'
import DureeMoyenneSejourParCategorieSocioProSortie from './DureeMoyenneSejourParCategorieSocioProSortie.vue'

const categorieSocioProList = listCategoriesSocioPro.map(e => ({
  name: e,
  nombreEntrees: 0,
  nombreSorties: 0,
}))
const motifsVoyageList = listMotifsVoyage.map(e => ({
  name: e,
  nombreEntrees: 0,
  nombreSorties: 0,
}))

const StatsMotifsVoyage = () => import('./StatsMotifsVoyage.vue')
const StatsCategorieSocioPro = () => import('./StatsCategorieSocioPro.vue')
const StatsPaysVoyageurs = () => import('./VoyageursParPaysSortie.vue')
const VoyageursParPaysEntree = () => import('./entrees/VoyageursParPaysEntree.vue')

const activeFilters = ['periode']

const statsData = {
  statsEntreesFrontieresTerrestres: {
    tableDatas: {
      departements: [],
      communes: [],
      arrondissements: [],
      villages: [],
    },
  },
  statsSortiesFrontieresTerrestres: {
    tableDatas: {
      departements: [],
      communes: [],
      arrondissements: [],
      villages: [],
    },
  },
  parCategorieSocioProEntreeEtSortie: categorieSocioProList,
  parMotifsVoyageEntreeEtSortie: motifsVoyageList,
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardText,
    StatisticsFilters,
    BButton,
    BSpinner,
    StatsMotifsVoyage,
    StatsCategorieSocioPro,
    StatsPaysVoyageurs,
    VoyageursParPaysEntree,
    VoyageursParPaysSortie,
    DureeTotalSejourParMotifDeVoyageEntree,
    DureeTotalSejourParMotifDeVoyageSortie,
    DureeTotalSejourParCategorieSocioProEntree,
    DureeTotalSejourParCategorieSocioProSortie,
    DureeMoyenneSejourParMotifDeVoyageEntree,
    DureeMoyenneSejourParMotifDeVoyageSortie,
    DureeMoyenneSejourParCategorieSocioProEntree,
    DureeMoyenneSejourParCategorieSocioProSortie,
    DureeTotalSejourParPaysEntree,
    DureeTotalSejourParPaysSortie,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'statistics', module: statisticsStoreModule },
      { path: 'formulaire', module: formulaireStoreModule },
    ]

    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      isLoadingTableData: false,

      isLoading: false,
      activeFilters,
      filtersForm: {},

      statsData,
    }
  },
  computed: {
    etablissementId() {
      return utilsService.etablissementId
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  async mounted() {
    await this.getStatistics()
  },
  methods: {
    ...mapActions('statistics', {
      action_getStatsFrontieresTerrestres: 'getStatsFrontieresTerrestres',
    }),
    ...mapActions('formulaire', {
      fetchFormulaires: 'fetchFormulaires',
      exportFormulaires: 'exportFormulaires',
    }),
    ...mapActions('etablissements', {
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val }
    },
    localFilter() {
      const query = this.localSearchQuery.toLowerCase()
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter(e => {
        if (!query) return true
        return e.name.toLowerCase().includes(query)
      })
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(e => true)
    },
    async getStatistics() {
      this.isLoadingTableData = true
      const periodeFilter = () => {
        const { from, to } = this.filtersForm.periode || {}
        if (!from && !to) return { createdAt: '' }
        const result = !to ? `${from},${from}` : `${from},${to}`
        return { createdAt: result }
      }

      const filters = {
        ...periodeFilter(),
      }

      delete filters.periode
      const processedFilters = buildFiltersForRequest(filters)
      const reqOptions = {
        params: {
          filter: processedFilters,
        },
      }
      await this.action_getStatsFrontieresTerrestres(reqOptions)
        .then(async res => {
          // console.log('action_getStatsFrontieresTerrestres', res)

          // await this.getListTypesEtablissement()
          // const { data } = res
          // const { resources } = data
          // const { options } = resources
          const {
            statsEntreesFrontieresTerrestres,
            statsSortiesFrontieresTerrestres,
            parCategorieSocioProEntreeEtSortie,
            parMotifsVoyageEntreeEtSortie,
          } = res || {}

          this.statsData.parMotifsVoyageEntreeEtSortie = parMotifsVoyageEntreeEtSortie
          this.statsData.parCategorieSocioProEntreeEtSortie = parCategorieSocioProEntreeEtSortie
          this.statsData.statsEntreesFrontieresTerrestres = {
            ...this.statsData.statsEntreesFrontieresTerrestres,
            ...statsEntreesFrontieresTerrestres,
          }
          this.statsData.statsSortiesFrontieresTerrestres = {
            ...statsSortiesFrontieresTerrestres,
          }
          // console.log('this.statsData.statsEntreesFrontieresTerrestres :>> ', this.statsData.statsEntreesFrontieresTerrestres);
          this.isLoadingTableData = false
          // console.log('stats', res)
        })
        .catch(error => {
          this.isLoadingTableData = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
