<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Durée moyenne du séjour à la sortie des frontières terrestres par
        catégorie socio-professionnelle
      </b-card-title>
      <!-- <b-dropdown
        text="Choisir une periode"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div v-append-export-button />

          <b-table
            responsive
            :items="getTableData"
            :fields="tableColumns"
            :busy="isLoadingTableData"
            show-empty
            empty-text="Aucune donnée trouvée"
            hover
          >
            <template v-if="false" #thead-top="data">
              <b-tr>
                <b-th />
                <b-th colspan="2" class="text-center"> Résident </b-th>
              </b-tr>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template #cell(moyenneDureeSejourResident)="data">
              <div class="">
                <b-badge variant="light-success">
                  {{ data.item.moyenneDureeSejourResident }}
                </b-badge>
              </div>
            </template>
            <template #cell(moyenneDureeSejourNonResident)="data">
              <div class="">
                <b-badge variant="light-danger">
                  {{ data.item.moyenneDureeSejourNonResident }}
                </b-badge>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BTable,
  BFormSelect,
  BFormInput,
  BSpinner,
  BBadge,
  BTh,
  BTr,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

import {
  listCategoriesSocioPro,
  listMotifsVoyage,
} from "@/services/utils/constants";

const categorieSocioProList = listCategoriesSocioPro.map((e) => ({
  name: e,
  nombreEntrees: 0,
  nombreSorties: 0,
}));

export default {
  components: {
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BTable,
    BFormSelect,
    BFormInput,
    BBadge,
    BTh,
    BTr,
  },
  props: {
    tableDatas: {
      type: Array,
      default: () => categorieSocioProList,
    },
    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      categorieSocioProList,
      // isLoadingTableData: false,

      // tableDatas: {
      //   departements: [],
      //   communes: [],
      //   arrondissements: [],
      //   villages: [],
      // },
      tableColumns: [
        {
          key: "name",
          label: "Catégorie Socio-Professionelle",
          sortable: true,
        },
        {
          key: "moyenneDureeSejourResident",
          label: "Résidents",
          sortable: true,
        },
        {
          key: "moyenneDureeSejourNonResident",
          label: "Non Résidents",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        // console.log('val :>> ', val);
        if (val) {
          this.categorieSocioProList = val;
        }
        // this.filteredTableDatas[this.selectedTypeLocalisation] = val[this.selectedTypeLocalisation]
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  async mounted() {},
  methods: {
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("statistics", {
      action_getStatisticsAgencesVoyageToursOperators:
        "getStatisticsAgencesVoyageToursOperators",
    }),
    ...mapActions("etablissements", {
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    localFilter() {
      // const query = this.localSearchQuery.toLowerCase()
      // this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
      //   this.selectedTypeLocalisation
      // ].filter(e => {
      //   if (!query) return true
      //   return e.name.toLowerCase().includes(query)
      // })
      // this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(
      //   e => true,
      // )
    },
    getRandomInt(min = 10, max = 1000) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  computed: {
    getTableData() {
      return this.categorieSocioProList;
      return this.filteredTableDatas[this.selectedTypeLocalisation];
    },
  },
};
</script>
